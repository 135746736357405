import isAfter from 'date-fns/isAfter';
import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { calendariosProvider, operariosProvider } from '../../api';
import { horarioDbValuesToFormValues, horarioFormValuesToDescripcion } from '../../api/companies';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditOperarioFormContent } from './EditOperarioFormContent';

export const HORARIOS_ROUTE = 'horarios';
export const VACACIONES_ROUTE = 'vacaciones';
export const CALENDARIOS_ROUTE = 'calendarios';

export default function EditOperarioForm() {
    const { id } = useParams();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(
        () =>
            operariosProvider.getAll(id).then(async (operario) => {
                const horarios = operario.horarios.map((horario) => {
                    const dias = horarioDbValuesToFormValues(horario);
                    return {
                        ...horario,
                        descripcion: horarioFormValuesToDescripcion(dias),
                        dias,
                    };
                });

                let horarioActual;
                for (const horario of horarios) {
                    if (!isAfter(new Date(), new Date(horario.fecha))) continue;

                    horarioActual = horario;
                    break;
                }

                const thisYear = new Date().getFullYear();
                const nextYear = thisYear + 1;

                const diasVacaciones = operario.dias_vacaciones;

                if (!diasVacaciones.some(({ year }) => year === thisYear)) {
                    diasVacaciones.push({ year: thisYear, dias: 0 });
                }
                if (!diasVacaciones.some(({ year }) => year === nextYear)) {
                    diasVacaciones.push({ year: nextYear, dias: 0 });
                }

                async function setupCalendarios() {
                    const calendarioById = {};

                    for (let i = 0; i < operario.calendarios.length; i++) {
                        const entry = operario.calendarios[i];
                        if (!calendarioById[entry.calendario_id]) {
                            calendarioById[entry.calendario_id] = await calendariosProvider.getById(
                                entry.calendario_id,
                            );
                        }

                        entry.calendario = calendarioById[entry.calendario_id];
                    }
                }

                await setupCalendarios();

                return {
                    ...operario,
                    dias_vacaciones: diasVacaciones,
                    horarioActual,
                    horarios,
                };
            }),
        [id],
    );
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!isRouteExact || !data) return;

        history.replace(`${url}/${HORARIOS_ROUTE}`);
    }, [data, url, isRouteExact]);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditOperarioFormContent />
                </EditForm>
            </>
        )
    );
}
