import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { isFuture } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { fichajesProvider, operariosProvider } from '../../../../api';
import useAuthState from '../../../../AuthState';
import { format, formatFullDate, formatISODate } from '../../../../utils';
import Button from '../../../common/Button';
import { ButtonDialog } from '../../../common/dialogs/ButtonDialog';
import MonthDaySelector from '../../../common/MonthDaySelector';
import ResumenDia from './ResumenDia';
import ResumenMes from './ResumenMes';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(1)}px`,
            padding: theme.spacing(2),
        },
        volverButton: {
            alignSelf: 'flex-start',
            transform: 'translateX(-12px)',
        },
        title: {
            textAlign: 'center',
            '& .MuiTypography-root:first-of-type': {
                fontWeight: 'bold',
            },
        },
        content: {
            padding: theme.spacing(0, 2, 2, 2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: `${theme.spacing(2)}px`,
        },
        leyenda: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(0.25)}px`,
            width: '100%',
        },
        leyendaItem: {
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
            '&>div': {
                borderRadius: 4,
                width: 18,
                height: 18,
            },
            '& .MuiTypography-root': {
                fontWeight: 400,
                lineHeight: '20px',
            },
        },
        resumen: {
            width: '100%',
            border: `1px solid ${theme.palette.neutral.grey1}`,
            '&>*': {
                padding: theme.spacing(2),
            },
            '&>*:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.neutral.grey1}`,
            },
        },
        resumenTitle: {
            fontWeight: '600',
        },
    }),
    { name: 'HistorialFichajes' },
);

const calendarColors = {
    day: '#163E88',
    noLaborable: '#4C60FE',
    pendiente: '#E94F4F',
    revisado: '#F3AC3D',
    verificado: '#30BDA1',
};

const calendarLegend = {
    day: 'Laborable, sin registro',
    noLaborable: 'No laborable',
    pendiente: 'En revisión por administración',
    revisado: 'Fichaje revisado, falta tu verificación',
    verificado: 'Fichaje verificado',
};

const useFichajeStyles = makeStyles(
    (theme) => ({
        noLaborable: {
            '& button': {
                backgroundColor: `${calendarColors.noLaborable} !important`,
            },
        },
        pendiente: {
            '& button': {
                backgroundColor: `${calendarColors.pendiente} !important`,
            },
        },
        revisado: {
            '& button': {
                backgroundColor: `${calendarColors.revisado} !important`,
            },
        },
        verificado: {
            '& button': {
                backgroundColor: `${calendarColors.verificado} !important`,
            },
        },
    }),
    { name: 'Fichaje' },
);

export function getClassNameFromFichaje(fecha, estado, noLaborable) {
    if (!isFuture(new Date(fecha)) && estado) {
        return estado.toLowerCase();
    }
    return noLaborable ? 'noLaborable' : null;
}

export function HistorialFichajes({ onVolver }) {
    const classes = useStyles();
    const fichajeClasses = useFichajeStyles();
    const [fichajes, setFichajes] = useState([]);
    const [noLaborables, setNoLaborables] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [selectedFichaje, setSelectedFichaje] = useState(null);
    const year = format(fecha, 'Y');
    const month = format(fecha, 'M');

    const {
        userInfo: { user_id: operarioId },
    } = useAuthState();

    useEffect(() => {
        if (operarioId) {
            fichajesProvider.getAll(`revision/${operarioId}/${year}/${month}`).then(setFichajes);
        }
    }, [operarioId, year, month]);

    useEffect(() => {
        operariosProvider.getAll(`no_laborables/${year}`).then(setNoLaborables);
    }, [year]);

    const classesByFecha = useMemo(() => {
        const fichajesEstado = Object.fromEntries(
            fichajes
                .filter((f) => f.fichaje !== null)
                .map(({ fichaje: { fecha, estado } }) => [fecha, estado.toLowerCase()]),
        );

        const days = [];
        const date = new Date(year, month - 1, 1);
        while (date.getMonth() === month - 1) {
            const currentDay = formatISODate(date);
            const className = getClassNameFromFichaje(
                currentDay,
                fichajesEstado[currentDay],
                noLaborables.includes(currentDay),
            );

            days.push([currentDay, className ? fichajeClasses[className] : null]);
            date.setDate(date.getDate() + 1);
        }

        return Object.fromEntries(days);
    }, [fichajes, noLaborables]);

    function onUpdated(fichajesVerificados) {
        setFichajes((fichajes) =>
            fichajes.map((f) => {
                if (f.fichaje === null) return f;

                const fichajeVerificado = fichajesVerificados.find((fv) => fv.id === f.fichaje.id);
                if (!fichajeVerificado) return f;

                if (selectedFichaje !== null && selectedFichaje.id === f.fichaje.id) {
                    setSelectedFichaje(fichajeVerificado);
                }

                return {
                    ...f,
                    fichaje: {
                        ...f.fichaje,
                        ...fichajeVerificado,
                    },
                };
            }),
        );
    }

    return (
        <div className={classes.root}>
            <Button
                color='primary'
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => {
                    if (selectedFichaje) {
                        setSelectedFichaje(null);
                    } else {
                        onVolver();
                    }
                }}
                className={classes.volverButton}
            >
                Volver
            </Button>
            <div className={classes.content}>
                <div className={classes.title}>
                    <Typography>{selectedFichaje ? 'Fichaje del' : 'Historial mensual de fichajes'}</Typography>
                    <Typography>
                        {selectedFichaje
                            ? formatFullDate(selectedFichaje.fecha)
                            : 'Selecciona un día para ver los detalles'}
                    </Typography>
                </div>
                <MonthDaySelector
                    showMonthSelector
                    showSelectedDay={false}
                    fecha={fecha}
                    classesByFecha={classesByFecha}
                    onDateChange={(date) => {
                        setFecha(date);

                        const dia = fichajes.find((f) => f.fichaje && f.fichaje.fecha === formatISODate(date));
                        if (dia && dia.fichaje) {
                            setSelectedFichaje(dia.fichaje);
                        }
                    }}
                    onMonthChange={(date) => {
                        setFecha(date);
                        setSelectedFichaje(null);
                    }}
                />
                <div className={classes.leyenda}>
                    {Object.entries(calendarColors).map(([key, color]) => (
                        <div className={classes.leyendaItem} key={key}>
                            <div style={{ backgroundColor: color }} />
                            <Typography variant='subtitle2'>{calendarLegend[key]}</Typography>
                        </div>
                    ))}
                </div>
                <Paper elevation={0} className={classes.resumen}>
                    <Typography variant='body2' component='div' className={classes.resumenTitle}>
                        {selectedFichaje
                            ? formatFullDate(selectedFichaje.fecha)
                            : `Resúmen de ${format(fecha, 'MMMM yyyy')}`}
                    </Typography>

                    {selectedFichaje && <ResumenDia fichaje={selectedFichaje} />}
                    {!selectedFichaje && <ResumenMes fichajes={fichajes} />}
                </Paper>
                {selectedFichaje ? (
                    <>
                        {selectedFichaje && selectedFichaje.estado !== 'VERIFICADO' && (
                            <>
                                {!selectedFichaje.operario_verificado && selectedFichaje.admin_verificado && (
                                    <Button
                                        color='info'
                                        fullWidth
                                        size='big'
                                        onClick={() => {
                                            fichajesProvider.verificarFichajes([selectedFichaje.id]).then(onUpdated);
                                        }}
                                    >
                                        Verificar fichaje
                                    </Button>
                                )}
                                {!selectedFichaje.operario_verificado && !selectedFichaje.admin_verificado ? (
                                    <Button color='info' size='big' disabled fullWidth>
                                        Fichaje pendiente de revisión
                                    </Button>
                                ) : (
                                    <Button
                                        size='big'
                                        color={
                                            !selectedFichaje.operario_verificado && selectedFichaje.admin_verificado
                                                ? 'outlined'
                                                : 'info'
                                        }
                                        outline={
                                            !selectedFichaje.operario_verificado && selectedFichaje.admin_verificado
                                        }
                                        rounded
                                        fullWidth
                                        onClick={() => {
                                            fichajesProvider
                                                .solicitarRevisionFichajes([selectedFichaje.id])
                                                .then(onUpdated);
                                        }}
                                    >
                                        Solicitar revisión del fichaje
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <ButtonDialog
                        button={
                            <Button color='info' fullWidth size='big'>
                                Verificar todos los fichajes
                            </Button>
                        }
                        dialogText='¿Estás seguro de que quieres verificar todos los fichajes del mes?'
                        dialogTitle='Verificar fichajes'
                        onConfirm={() => {
                            fichajesProvider
                                .verificarFichajes(
                                    fichajes.filter((f) => f.fichaje?.estado === 'REVISADO').map((f) => f.fichaje.id),
                                )
                                .then(onUpdated);
                        }}
                        confirmButtonText='Verificar'
                    />
                )}
            </div>
        </div>
    );
}

HistorialFichajes.propTypes = {
    onVolver: PropTypes.func.isRequired,
};
