import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { companiesProvider } from '../../../../../api';
import { useFetchData } from '../../../../../hooks/useFetchData';
import Button from '../../../../common/Button';
import BaseListView, { withTableState } from '../../../../common/list/ListView';
import OperariosIcon from '../../../../icons/Operarios';
import AsignarHorarioOperariosDialog from './AsignarHorarioOperariosDialog';
import EditHorariosDialog from './EditHorariosDialog';
import HorarioNocturnoDialog from './HorarioNocturnoDialog';
import NewHorariosDialog from './NewHorariosDialog';

const ListView = withTableState('delegaciones', BaseListView);

export default function AjustesHorarios({ classes }) {
    const fetchDataFn = useCallback(() => companiesProvider.getHorariosEmpresa(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        companiesProvider
            .deleteHorarioEmpresa(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'nombre',
            },
            {
                Header: 'Operarios',
                accessor: 'operarios',
                Cell: ({ value }) => value.map((o) => o.nombre).join(', '),
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            hideEdit: true,
            deleteConfirmationText: 'borrar horario',
            batchComponents: (
                <NewHorariosDialog
                    button={
                        <Button color='transparent' aria-label='Nuevo horario' startIcon={<AddIcon />}>
                            Nuevo horario
                        </Button>
                    }
                    onSave={() => {
                        fetchData();
                    }}
                />
            ),
        }),
        [],
    );

    return (
        <>
            <Paper elevation={0}>
                <ListView
                    tableTitle={'Lista de horarios'}
                    basePath='/horarios'
                    columns={columns}
                    data={data}
                    options={options}
                    onDelete={onDelete}
                    extraActions={(row) => (
                        <>
                            {!row.por_defecto ? (
                                <Tooltip title='Marcar por defecto'>
                                    <IconButton
                                        onClick={() => {
                                            companiesProvider
                                                .setHorarioPorDefecto(row.id)
                                                .then(() => fetchData())
                                                .catch((err) => snackbar.showMessage(err.message));
                                        }}
                                    >
                                        <BookmarkBorderIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title='Horario por defecto'>
                                    <IconButton>
                                        <BookmarkIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <AsignarHorarioOperariosDialog
                                id={row.id}
                                button={
                                    <Tooltip title='Asignar a operarios'>
                                        <IconButton>
                                            <OperariosIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onSave={fetchData}
                            />
                            <EditHorariosDialog
                                id={row.id}
                                button={
                                    <Tooltip title='Editar horario'>
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onSave={fetchData}
                            />
                            <NewHorariosDialog
                                duplicateId={row.id}
                                button={
                                    <Tooltip title='Duplicar horario'>
                                        <IconButton>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onSave={fetchData}
                            />
                        </>
                    )}
                />
            </Paper>
            <HorarioNocturnoDialog />
        </>
    );
}

AjustesHorarios.propTypes = {
    classes: PropTypes.any,
};
