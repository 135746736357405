import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import classnames from 'classnames';
import clsx from 'clsx';
import { isFuture } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isValid from 'date-fns/isValid';
import { useSnackbar } from 'material-ui-snackbar-provider';
import * as PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { fichajesProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import useAuthState from '../../AuthState';
import { format, formatISODate, formatTiempo } from '../../utils';
import Button from '../common/Button';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { MarcajeTimePicker } from '../common/fields/KeyboardDatePicker';
import FiltersOperario from '../control_jornada/FiltersOperario';
import CertificadoFichajesIcon from '../icons/CertificadoFichajes';
import DownloadIcon from '../icons/Download';
import ConfigurarRevisionDialog from './ConfigurarRevisionDialog';
import { EditFichajeButton } from './EditarFichaje';
import { EstadoRevisionChip } from './EstadoChip';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
            '.MuiTableRow-head:nth-of-type(2) &.MuiTableCell-head.MuiTableCell-stickyHeader': {
                top: 33,
            },
            '&.fixed': {
                backgroundColor: theme.palette.neutral.hoverCell,
                minWidth: 106,
                width: 106,
                maxWidth: 106,
            },
            '&.marcaje': {
                width: 172,
                minWidth: 172,
                maxWidth: 172,
            },
            '&.fill': {
                width: '100%',
            },
            '&.error': {
                color: theme.palette.error.main,
            },
            '& div.error': {
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(0.5)}px`,
                color: theme.palette.error.main,
            },
            '& div.error .MuiSvgIcon-root': {
                fontSize: 20,
            },
            '& .touched .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.warning.main,
            },
            '& .touched .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.warning.backgroundTint,
            },
            '&:nth-last-child(2)': {
                width: 166,
                minWidth: 166,
                maxWidth: 166,
            },
            '&:last-child': {
                width: 96,
                minWidth: 96,
                maxWidth: 96,
            },
            '.MuiTableRow-root:not(.empty):hover &': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
        footer: {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const TableRow = withStyles(
    (theme) => ({
        root: {
            '&:not(.empty):hover': {
                backgroundColor: theme.palette.neutral.grey3,
            },
        },
    }),
    { name: 'TableRow' },
)(MuiTableRow);

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'flex',
            gap: `${theme.spacing(4)}px`,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        noLaborable: {
            '& td:not($diferencia)': {
                color: theme.palette.neutral.primary,
            },
        },
        diferencia: {
            color: theme.palette.error.main,
        },
        paper: {
            marginBottom: theme.spacing(3),
            flex: 1,
        },
        tableTitle: {
            margin: theme.spacing(1, 2),
            height: 32,
            display: 'flex',
            alignItems: 'center',
        },
        tableTitleActions: {
            marginLeft: 'auto',
            display: 'flex',
        },
        container: {
            maxHeight: 400,
        },
        stickyFooter: {
            '& tbody': {
                marginBottom: 33,
            },
            '& .MuiTableCell-footer': {
                left: 0,
                bottom: 0,
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#fafafa',
            },
        },
        marcajeWrapperHeader: {
            display: 'flex',
            gap: theme.spacing(1),
            '& span': {
                width: 66,
            },
        },
        marcajeWrapper: {
            display: 'flex',
            gap: theme.spacing(1),
        },
        timePicker: {
            '& .MuiInputBase-input': {
                width: 36,
                fontSize: theme.typography.subtitle2.fontSize,
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: 0,
                display: 'none',
            },
        },
        addButton: {
            visibility: 'hidden',
            'tr:hover &': {
                visibility: 'visible',
            },
        },
        filterActions: {
            marginLeft: 'auto',
        },
        estadoChips: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
        },
        expandCell: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'RevisionFichajes' },
);

const STORAGE_KEY = 'RevisionFichajes-prefs';
const defaultPrefs = {
    operario: null,
    selectedDate: null,
};

function RevisionFichajesFooter({ expanded, maxMarcajes, totales }) {
    return (
        <TableFooter>
            <TableRow>
                <TableCell>Totales</TableCell>
                {expanded && (
                    <>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.horario))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.vacaciones))}</TableCell>
                        <TableCell>{formatTiempo(createTiempo(totales.laborables.ausencias))}</TableCell>
                    </>
                )}
                <TableCell>{formatTiempo(createTiempo(totales.laborables.efectivas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.fichadas))}</TableCell>
                <TableCell>{formatTiempo(createTiempo(totales.laborables.diferencia, true))}</TableCell>
                {maxMarcajes > 0 && <TableCell colSpan={maxMarcajes} />}
                <TableCell colSpan={3}></TableCell>
            </TableRow>
        </TableFooter>
    );
}

RevisionFichajesFooter.propTypes = {
    totales: PropTypes.shape({
        laborables: PropTypes.shape({
            fichadas: PropTypes.number,
            horario: PropTypes.number,
            efectivas: PropTypes.number,
            vacaciones: PropTypes.number,
            ausencias: PropTypes.number,
            diferencia: PropTypes.number,
        }),
    }),
    maxMarcajes: PropTypes.any,
    expanded: PropTypes.bool,
};

export default function RevisionFichajes() {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const [operario, setOperario] = useState(null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    });
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resumen, setResumen] = useState([]);
    const [containerHeight, setContainerHeight] = useState(500);

    const {
        userInfo: {
            preferencias: {
                minutos_diferencia_fichajes_min: diferenciaMin = 0,
                minutos_diferencia_fichajes_max: diferenciaMax = 0,
            } = {},
        },
    } = useAuthState();

    function loadPrefs() {
        const prefs = JSON.parse(localStorage.getItem(STORAGE_KEY)) || defaultPrefs;
        setOperario(prefs.operario);
        if (prefs.selectedDate) setSelectedDate(new Date(prefs.selectedDate));
    }

    function updatePrefs() {
        localStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                operario,
                selectedDate,
            }),
        );
    }
    useEffect(() => {
        loadPrefs();
    }, []);

    useEffect(() => {
        updatePrefs();
    }, [operario, selectedDate]);

    const reloadData = useCallback(
        function reloadData() {
            setLoading(true);
            fichajesProvider.getRevisionOperario(operario.id, selectedDate).then((resumen) => {
                setLoading(false);
                setResumen(
                    resumen.map((resumenFecha) => {
                        resumenFecha.fecha = new Date(resumenFecha.fecha);

                        if (!resumenFecha.fichaje) {
                            resumenFecha.fichaje = {
                                fecha: resumenFecha.fecha,
                                operario_id: operario.id,
                                marcajes: [],
                                diferencia: 0,
                                estado: 'PENDIENTE',
                                trabajados: 0,
                            };
                        }

                        onLoadResumenFecha(resumenFecha);

                        return resumenFecha;
                    }),
                );
            });
        },
        [operario, selectedDate],
    );

    useEffect(() => {
        setResumen([]);

        if (!selectedDate) return;

        if (!operario) return;

        reloadData();
    }, [selectedDate, operario, reloadData]);

    const updateContainerHeight = useCallback((node) => {
        if (node !== null) {
            setContainerHeight(node.getBoundingClientRect().height - 32);
        }
    }, []);

    const { totales, maxMarcajes } = useMemo(() => {
        const totales = {
            laborables: {
                horario: 0,
                vacaciones: 0,
                ausencias: 0,
                efectivas: 0,
                fichadas: 0,
                diferencia: 0,
            },
        };

        let maxMarcajes = 0;
        resumen.forEach((resumenFecha) => {
            totales.laborables.horario += resumenFecha.laborables.horario;
            totales.laborables.vacaciones += resumenFecha.laborables.vacaciones;
            totales.laborables.ausencias += resumenFecha.laborables.ausencias;
            totales.laborables.efectivas += resumenFecha.laborables.efectivas;
            totales.laborables.fichadas += resumenFecha.laborables.fichadas;
            totales.laborables.diferencia += resumenFecha.laborables.diferencia;

            if (resumenFecha.fichaje) {
                maxMarcajes = Math.max(maxMarcajes, resumenFecha.fichaje.marcajes.length);
            }
        });

        return { totales, maxMarcajes };
    }, [resumen]);

    function onLoadResumenFecha(resumenFecha) {
        resumenFecha.fichaje.marcajes = resumenFecha.fichaje.marcajes.map((marcaje) => ({
            ...marcaje,
            hora_entrada: new Date(marcaje.hora_entrada),
            hora_salida: marcaje.hora_salida ? new Date(marcaje.hora_salida) : null,
            hora_entrada_touched: false,
            hora_salida_touched: false,
        }));

        resumenFecha.fichaje = getValidatedFichaje(resumenFecha.fichaje);
        resumenFecha.fichaje.touched = false;
        resumenFecha.laborables.fichadas = resumenFecha.fichaje.totalMarcajes;
        resumenFecha.laborables.diferencia = resumenFecha.laborables.fichadas - resumenFecha.laborables.efectivas;
    }

    function getValidatedFichaje(fichaje) {
        const newFichaje = {
            ...fichaje,
            marcajes: fichaje.marcajes.map((marcaje, marcajeIdx) => {
                const newMarcaje = {
                    ...marcaje,
                };

                let horaEntradaError = false;
                let horaSalidaError = false;
                const marcajeErrors = [];

                // if (newMarcaje.hora_entrada === null) horaEntradaError = true;
                // if (newMarcaje.hora_salida === null) horaSalidaError = true;

                if (
                    newMarcaje.hora_entrada &&
                    newMarcaje.hora_salida &&
                    newMarcaje.hora_entrada > newMarcaje.hora_salida
                ) {
                    horaEntradaError = true;
                    horaSalidaError = true;
                    marcajeErrors.push('La hora de salida debe ser posterior a la de entrada');
                }

                if (newMarcaje.hora_entrada && isFuture(newMarcaje.hora_entrada)) {
                    horaEntradaError = true;
                    marcajeErrors.push('La hora de entrada no puede estar en el futuro');
                }

                if (newMarcaje.hora_salida && isFuture(newMarcaje.hora_salida)) {
                    horaSalidaError = true;
                    marcajeErrors.push('La hora de salida no puede estar en el futuro');
                }

                if (
                    marcajeIdx > 0 &&
                    newMarcaje.hora_entrada &&
                    fichaje.marcajes[marcajeIdx - 1].hora_salida &&
                    newMarcaje.hora_entrada < fichaje.marcajes[marcajeIdx - 1].hora_salida
                ) {
                    horaEntradaError = true;
                    marcajeErrors.push('No se pueden solapar los marcajes');
                }
                if (
                    marcajeIdx < fichaje.marcajes.length - 1 &&
                    newMarcaje.hora_salida &&
                    fichaje.marcajes[marcajeIdx + 1].hora_entrada &&
                    newMarcaje.hora_salida > fichaje.marcajes[marcajeIdx + 1].hora_entrada
                ) {
                    horaSalidaError = true;
                    marcajeErrors.push('No se pueden solapar los marcajes');
                }

                newMarcaje.horaEntradaError = horaEntradaError;
                newMarcaje.horaSalidaError = horaSalidaError;
                newMarcaje.errors = marcajeErrors;

                return newMarcaje;
            }),
        };

        const hasErrors = newFichaje.marcajes.some((marcaje) => marcaje.horaEntradaError || marcaje.horaSalidaError);

        newFichaje.errors = [];
        newFichaje.marcajes.forEach((marcaje) => {
            newFichaje.errors.push(...marcaje.errors);
        });

        if (hasErrors) {
            newFichaje.totalMarcajes = null;
        } else {
            newFichaje.totalMarcajes = 0;
            newFichaje.marcajes.forEach((marcaje) => {
                if (marcaje.hora_entrada && marcaje.hora_salida) {
                    newFichaje.totalMarcajes += differenceInMinutes(marcaje.hora_salida, marcaje.hora_entrada);
                }
            });
        }

        return newFichaje;
    }

    const { hasErrors, hasTouched } = useMemo(
        () => ({
            hasErrors: resumen.some((resumenFecha) =>
                resumenFecha.fichaje.marcajes.some((marcaje) => marcaje.horaEntradaError || marcaje.horaSalidaError),
            ),
            hasTouched: resumen.some((resumenFecha) => resumenFecha.fichaje.touched),
        }),
        [resumen],
    );

    function onUpdateMarcaje(resumenFechaIdx, updatedIdx, name, value) {
        setResumen((resumen) =>
            resumen.map((resumenFecha, i) => {
                if (resumenFechaIdx !== i) return resumenFecha;

                resumenFecha.fichaje.marcajes[updatedIdx][name] = !isValid(value) ? null : value;
                resumenFecha.fichaje.marcajes[updatedIdx][`${name}_touched`] = true;

                const fichaje = getValidatedFichaje(resumenFecha.fichaje);
                fichaje.touched = true;

                const newResumenFecha = {
                    ...resumenFecha,
                    laborables: {
                        ...resumenFecha.laborables,
                        fichadas: fichaje.totalMarcajes,
                        diferencia: fichaje.totalMarcajes - resumenFecha.laborables.efectivas,
                    },
                    fichaje,
                };

                return newResumenFecha;
            }),
        );
    }

    const exportar = () => {
        fichajesProvider
            .exportar(selectedDate, operario?.id)
            .then(async (data) => {
                const uri = window.URL.createObjectURL(await data.blob());

                const a = document.createElement('a');
                a.style = { display: 'none' };
                a.href = uri;
                a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(uri);
                document.body.removeChild(a);
            })
            .catch((err) => {
                // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                console.error(err);
            });
    };

    const exportarTodos = () => {
        fichajesProvider
            .exportar(selectedDate)
            .then(async (data) => {
                const uri = window.URL.createObjectURL(await data.blob());

                const a = document.createElement('a');
                a.style = { display: 'none' };
                a.href = uri;
                a.download = data.headers.get('Content-Disposition').split('filename=')[1];
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(uri);
                document.body.removeChild(a);
            })
            .catch((err) => {
                // snackbar.showMessage('Ha ocurrido un error durante la descarga');
                console.error(err);
            });
    };

    return (
        <>
            <div className={classes.header}>
                <Typography variant='h1' className={classes.title}>
                    Revisión de fichajes
                </Typography>
            </div>
            <FiltersOperario
                onChangeOperario={(operario) => setOperario(operario ?? null)}
                defaultOperarioId={operario?.id ?? null}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                includeAdministrativos
            >
                <div className={classes.filterActions}>
                    {hasTouched && (
                        <Button color='primary' onClick={reloadData}>
                            Descartar cambios
                        </Button>
                    )}
                    <Tooltip title={hasErrors ? 'Soluciona los errores antes de guardar' : ''} arrow>
                        <span>
                            <Button
                                color='info'
                                onClick={() => {
                                    const fichajesToSave = resumen
                                        .map((resumenFecha) => resumenFecha.fichaje)
                                        .filter((fichaje) => fichaje.touched)
                                        .map((fichaje) => ({
                                            id: fichaje.id,
                                            operario_id: fichaje.operario_id,
                                            fecha: new Date(fichaje.fecha),
                                            marcajes: fichaje.marcajes.map((marcaje) => ({
                                                id: marcaje.id,
                                                hora_entrada: marcaje.hora_entrada,
                                                hora_salida: marcaje.hora_salida,
                                            })),
                                        }));

                                    fichajesProvider
                                        .saveRevisionOperario(operario.id, fichajesToSave)
                                        .then((fichajes) => {
                                            setResumen((resumen) =>
                                                resumen.map((resumenFecha) => {
                                                    const fichaje = fichajes.find(
                                                        (fichaje) =>
                                                            formatISODate(resumenFecha.fecha) === fichaje.fecha,
                                                    );
                                                    if (!fichaje) return resumenFecha;

                                                    const newResumenFecha = {
                                                        ...resumenFecha,
                                                        fichaje,
                                                    };
                                                    onLoadResumenFecha(newResumenFecha);
                                                    return newResumenFecha;
                                                }),
                                            );
                                            snackbar.showMessage('Fichajes guardados correctamente');
                                        });
                                }}
                                disabled={hasErrors || !hasTouched}
                            >
                                Guardar
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title='Descargar XLS con los fichajes de todos los operarios'>
                        <IconButton style={{ marginLeft: 'auto' }} onClick={exportarTodos}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <ConfigurarRevisionDialog
                        button={
                            <IconButton>
                                <SettingsIcon />
                            </IconButton>
                        }
                    />
                </div>
            </FiltersOperario>
            <Paper elevation={0} className={classes.paper} ref={updateContainerHeight}>
                <div className={classes.tableTitle}>
                    <Typography variant='h6'>
                        {operario ? `Operario: ${operario.nombre}` : 'Selecciona un operario para ver sus datos'}
                    </Typography>

                    <div className={classes.tableTitleActions}>
                        <IconButton onClick={exportar}>
                            <DownloadIcon />
                        </IconButton>

                        <Tooltip title='Descargar certificado jornada laboral'>
                            <IconButton
                                disabled={hasErrors}
                                aria-label='Descargar certificado'
                                onClick={() =>
                                    window.open(fichajesProvider.getCertificadoURL(selectedDate, operario?.id))
                                }
                            >
                                <CertificadoFichajesIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <TableContainer
                    className={classes.container}
                    style={{ minHeight: containerHeight, maxHeight: containerHeight }}
                >
                    <Table size='small' stickyHeader className={classes.stickyFooter}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={expanded ? 6 : 3}>
                                    <div className={classes.expandCell}>
                                        Jornada del operario{' '}
                                        <IconButton size='small' onClick={() => setExpanded((expanded) => !expanded)}>
                                            {expanded ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                        </IconButton>
                                    </div>
                                </TableCell>
                                {new Array(maxMarcajes).fill(null).map((_, i) => (
                                    <TableCell key={i}>Marcaje {i + 1}</TableCell>
                                ))}
                                <TableCell colSpan={3}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='fixed'>Fecha</TableCell>
                                {expanded && (
                                    <>
                                        <TableCell className='fixed'>Laborables</TableCell>
                                        <TableCell className='fixed'>Vacaciones</TableCell>
                                        <TableCell className='fixed'>Ausencias</TableCell>
                                    </>
                                )}
                                <TableCell className='fixed'>Efectivas</TableCell>
                                <TableCell className='fixed'>Fichadas</TableCell>
                                <TableCell className='fixed'>Diferencia</TableCell>
                                {new Array(maxMarcajes).fill(null).map((_, i) => (
                                    <TableCell key={i}>
                                        <div className={classes.marcajeWrapperHeader}>
                                            <span>Entrada</span>
                                            <span>Salida</span>
                                        </div>
                                    </TableCell>
                                ))}
                                <TableCell></TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resumen.length === 0 && (
                                <TableRow className='empty'>
                                    <TableCell
                                        colSpan={expanded ? 10 : 7}
                                        align='center'
                                        style={{ height: containerHeight - 34 * 3 }}
                                    >
                                        {loading ? <CircularProgress /> : 'No hay datos'}
                                    </TableCell>
                                </TableRow>
                            )}
                            {resumen.map((resumenFecha, i) => (
                                <RevisionFichaje
                                    resumenFecha={resumenFecha}
                                    maxMarcajes={maxMarcajes}
                                    key={i}
                                    onAddMarcaje={(numMarcajes) =>
                                        setResumen((resumen) =>
                                            resumen.map((resumenFecha, j) =>
                                                i !== j
                                                    ? resumenFecha
                                                    : {
                                                          ...resumenFecha,
                                                          fichaje: {
                                                              ...resumenFecha.fichaje,
                                                              marcajes: [
                                                                  ...resumenFecha.fichaje.marcajes,
                                                                  {
                                                                      hora_entrada: null,
                                                                      hora_salida: null,
                                                                  },
                                                              ],
                                                          },
                                                      },
                                            ),
                                        )
                                    }
                                    diferenciaMin={diferenciaMin}
                                    diferenciaMax={diferenciaMax}
                                    expanded={expanded}
                                    onUpdateMarcaje={(...args) => onUpdateMarcaje(i, ...args)}
                                    onUpdateFichaje={(updatedFichaje) => {
                                        setResumen((resumen) =>
                                            resumen.map((resumenFecha) => {
                                                if (formatISODate(resumenFecha.fecha) !== updatedFichaje.fecha)
                                                    return resumenFecha;

                                                const newResumenFecha = {
                                                    ...resumenFecha,
                                                    fichaje: updatedFichaje,
                                                };

                                                onLoadResumenFecha(newResumenFecha);
                                                return newResumenFecha;
                                            }),
                                        );
                                        snackbar.showMessage('Fichajes guardados correctamente');
                                    }}
                                    onDeleteFichaje={() => reloadData()}
                                />
                            ))}
                        </TableBody>
                        <RevisionFichajesFooter expanded={expanded} totales={totales} maxMarcajes={maxMarcajes} />
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

const RevisionFichaje = memo(
    function RevisionFichaje({
        resumenFecha,
        maxMarcajes,
        onAddMarcaje,
        expanded,
        onUpdateMarcaje,
        onUpdateFichaje,
        onDeleteFichaje,
        diferenciaMin,
        diferenciaMax,
    }) {
        const classes = useStyles();

        const marcajes = resumenFecha.fichaje.marcajes;
        const numMarcajes = marcajes.length;
        const errors = [...new Set(resumenFecha.fichaje.errors)];
        const hasErrors = errors.length > 0;

        const diferenciaHasError =
            resumenFecha.laborables.diferencia < -diferenciaMin || resumenFecha.laborables.diferencia > diferenciaMax;

        const touched = marcajes.some((marcaje) => marcaje.hora_salida_touched || marcaje.hora_entrada_touched);
        const isComplete = marcajes.every((marcaje) => marcaje.hora_entrada && marcaje.hora_salida);

        return (
            <TableRow className={resumenFecha.laborables.efectivas === 0 ? classes.noLaborable : null}>
                <TableCell className='fixed'>{format(resumenFecha.fecha, 'EEE dd')}</TableCell>

                {expanded && (
                    <>
                        <TableCell className='fixed'>
                            {resumenFecha.laborables.horario !== null
                                ? formatTiempo(createTiempo(resumenFecha.laborables.horario))
                                : 'No laborable'}
                        </TableCell>
                        <TableCell className='fixed'>
                            {resumenFecha.laborables.horario !== null
                                ? formatTiempo(createTiempo(resumenFecha.laborables.vacaciones))
                                : 'No laborable'}
                        </TableCell>
                        <TableCell className='fixed'>
                            {resumenFecha.laborables.horario !== null
                                ? formatTiempo(createTiempo(resumenFecha.laborables.ausencias))
                                : 'No laborable'}
                        </TableCell>
                    </>
                )}
                <TableCell className='fixed'>
                    {resumenFecha.laborables.horario !== null
                        ? formatTiempo(createTiempo(resumenFecha.laborables.efectivas))
                        : 'No laborable'}
                </TableCell>
                {resumenFecha.laborables.fichadas === null ? (
                    <>
                        <TableCell>
                            <Tooltip arrow title={errors.length > 0 ? errors.join('. \n') : ''}>
                                <div className='error'>
                                    <InfoIcon />
                                    Error
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell></TableCell>
                    </>
                ) : (
                    <>
                        <TableCell className='fixed'>
                            {formatTiempo(createTiempo(resumenFecha.laborables.fichadas))}
                        </TableCell>
                        <TableCell className={classnames('fixed', diferenciaHasError ? 'error' : null)}>
                            {formatTiempo(createTiempo(resumenFecha.laborables.diferencia, true))}
                        </TableCell>
                    </>
                )}

                {marcajes.map((marcaje, marcajeIdx) => (
                    <TableCell key={marcajeIdx} className='marcaje'>
                        <div className={classes.marcajeWrapper}>
                            <MarcajeTimePicker
                                inputVariant='outlined'
                                className={clsx(classes.timePicker, marcaje.hora_entrada_touched ? 'touched' : null)}
                                onChange={(date) => {
                                    onUpdateMarcaje(marcajeIdx, 'hora_entrada', date);
                                }}
                                forceDate={resumenFecha.fecha}
                                value={marcaje.hora_entrada}
                                hasError={marcaje.horaEntradaError || marcaje.hora_entrada === null}
                            />
                            <MarcajeTimePicker
                                inputVariant='outlined'
                                className={clsx(classes.timePicker, marcaje.hora_salida_touched ? 'touched' : null)}
                                onChange={(date) => {
                                    onUpdateMarcaje(marcajeIdx, 'hora_salida', date);
                                }}
                                forceDate={resumenFecha.fecha}
                                value={marcaje.hora_salida}
                                hasError={marcaje.horaSalidaError || marcaje.hora_salida === null}
                            />
                        </div>
                    </TableCell>
                ))}

                {new Array(maxMarcajes - numMarcajes + 1).fill(null).map((_, i) => (
                    <TableCell key={i} className={i === maxMarcajes - numMarcajes ? 'fill' : null}>
                        {i === 0 && (
                            <IconButton
                                size='small'
                                className={classes.addButton}
                                onClick={() => {
                                    onAddMarcaje(numMarcajes + 1);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </TableCell>
                ))}

                <TableCell>
                    {resumenFecha.fichaje.id && (
                        <div className={classes.estadoChips}>
                            <EstadoRevisionChip
                                size='small'
                                label='EMP'
                                className={resumenFecha.fichaje.operario_verificado ? 'revisado' : 'no_revisado'}
                                icon={resumenFecha.fichaje.operario_verificado ? <DoneIcon /> : <CloseIcon />}
                            />
                            <EstadoRevisionChip
                                size='small'
                                label='ADM'
                                className={resumenFecha.fichaje.admin_verificado ? 'revisado' : 'no_revisado'}
                                icon={resumenFecha.fichaje.admin_verificado ? <DoneIcon /> : <CloseIcon />}
                            />
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    {resumenFecha.fichaje.operario_verificado && !resumenFecha.fichaje.admin_verificado && isComplete && (
                        <IconButton
                            size='small'
                            onClick={() =>
                                fichajesProvider
                                    .revisar([resumenFecha.fichaje.id])
                                    .then((revisados) => onUpdateFichaje(revisados[0]))
                            }
                            disabled={touched || hasErrors}
                        >
                            <DoneIcon />
                        </IconButton>
                    )}
                    <EditFichajeButton onSave={onUpdateFichaje} fichaje={resumenFecha.fichaje} />

                    {resumenFecha.fichaje.id && (
                        <DeleteDialog
                            text='¿Deseas borrar este fichaje?'
                            button={
                                <Tooltip title='Borrar fichaje'>
                                    <IconButton aria-label='Borrar fichaje' size='small'>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            onConfirm={() => {
                                fichajesProvider.delete(resumenFecha.fichaje.id).then(onDeleteFichaje);
                            }}
                        />
                    )}
                </TableCell>
            </TableRow>
        );
    },
    (prevProps, nextProps) =>
        prevProps.resumenFecha.fichaje === nextProps.resumenFecha.fichaje &&
        prevProps.expanded === nextProps.expanded &&
        prevProps.diferenciaMin === nextProps.diferenciaMin &&
        prevProps.diferenciaMax === nextProps.diferenciaMax &&
        prevProps.maxMarcajes === nextProps.maxMarcajes,
);

RevisionFichaje.propTypes = {
    resumenFecha: PropTypes.any,
    maxMarcajes: PropTypes.any,
    operarioId: PropTypes.any,
    expanded: PropTypes.bool,
    onAddMarcaje: PropTypes.func,
    onUpdateMarcaje: PropTypes.func,
    onUpdateFichaje: PropTypes.func,
    onDeleteFichaje: PropTypes.func,
    diferenciaMin: PropTypes.any,
    diferenciaMax: PropTypes.any,
};
