import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { calendariosProvider } from '../../../../../api';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import { KeyboardDatePicker } from '../../../../common/fields/KeyboardDatePicker';
import OperariosField from '../../../../common/fields/OperariosField';
import DialogForm from '../../../../common/forms/DialogForm';

function AsignarCalendarioOperariosDialog({ id, onSave, ...props }) {
    const snackbar = useSnackbar();
    return (
        <DialogForm
            title='Asignar calendario a operarios'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    fecha: new Date(),
                    operarios: [],
                },
                enableReinitialize: true,
                onSubmit: ({ operarios, fecha }, { setSubmitting, setFieldError }) => {
                    const operarioIds = operarios.map((o) => o.id);

                    calendariosProvider
                        .asignarOperarios(id, fecha, operarioIds)
                        .then(() => {
                            onSave && onSave();
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            snackbar.showMessage('Ha ocurrido un error');
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2} direction='column'>
                    <Grid item xs={12}>
                        <KeyboardDatePicker label='El nuevo calendario entra en vigor el' name='fecha' autoOk />
                    </Grid>
                    <Grid item xs={12}>
                        <OperariosField addSelectAll />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

AsignarCalendarioOperariosDialog.propTypes = {
    id: PropTypes.string.isRequired,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

export default withButtonOpener(AsignarCalendarioOperariosDialog);
