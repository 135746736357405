import { useMemo } from 'react';
import { formatDate } from '../../../utils';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import { DateColumnFilter, dateFilterFn } from '../../common/list/DateColumnFilter';
import ListView from '../../common/list/ListView';

export default function CalendariosTable() {
    const { data: calendarios } = useEditFormData('calendarios');

    const columns = useMemo(
        () => [
            {
                Header: 'Calendario',
                accessor: 'calendario.nombre',
            },
            {
                Header: 'Fecha de cambio',
                accessor: 'fecha',
                Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Calendario inicial'),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            addRoute: null,
            tableOptions: {
                usePagination: false,
                useSorting: false,
            },
        }),
        [],
    );

    return (
        <ListView
            tableTitle='Historial de cambios de calendario'
            columns={columns}
            data={calendarios}
            options={options}
        />
    );
}
