import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ReplayIcon from '@material-ui/icons/Replay';
import { es } from 'date-fns/locale';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import * as Yup from 'yup';
import { calendariosProvider } from '../../../../../api';
import { withButtonOpener } from '../../../../../hooks/withButtonOpener';
import { formatISODate } from '../../../../../utils';
import Button from '../../../../common/Button';
import DialogForm from '../../../../common/forms/DialogForm';

const HorariosSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogPaper: {},
        colorEditor: {
            display: 'flex',
            gap: `${theme.spacing(2)}px`,
        },
        descripcion: {
            whiteSpace: 'pre-line',
        },
        calendar: {
            '&.rdp': {
                margin: 0,
                fontSize: 12,
                '--rdp-cell-size': '32px',
                '--rdp-caption-font-size': '14px',
            },
            '& .rdp-months': {
                flexWrap: 'wrap',
                gap: `${theme.spacing(3)}px`,
            },
            '& .rdp-month': {
                margin: 0,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        actions: {
            marginLeft: 'auto',
            display: 'flex',
            gap: `${theme.spacing(1)}px`,
        },
    }),
    { name: 'EditCalendarioDialog' },
);

function EditCalendarioDialog({ id, onSave, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [calendario, setCalendario] = useState(null);

    useEffect(() => {
        if (!props.open) {
            setCalendario(null);
            return;
        }

        calendariosProvider.getById(id).then(setCalendario);
    }, [id, props.open]);

    if (!calendario) return null;

    return (
        <DialogForm
            title='Editar calendario'
            maxWidth='lg'
            className={classes.dialogPaper}
            FormikProps={{
                initialValues: {
                    nombre: calendario.nombre,
                    year: new Date().getFullYear(),
                    dates: [],
                },
                enableReinitialize: true,
                validationSchema: HorariosSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    const payload = {
                        ...values,
                        dates: values.dates.map((d) => formatISODate(d)),
                    };

                    calendariosProvider
                        .save(payload, id)
                        .then((data) => {
                            onSave && onSave(data.id);
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                useEffect(() => {
                    calendariosProvider.getNoLaborables(values.year, id).then((dates) =>
                        setFieldValue(
                            'dates',
                            dates.map((d) => new Date(d)),
                        ),
                    );
                }, [values.year]);

                return (
                    <Grid container spacing={2} direction='column'>
                        <Grid item xs={12}>
                            <TextField name='nombre' label='Nombre' fullWidth />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>El año</Typography>
                            <IconButton onClick={() => setFieldValue('year', values.year - 1)}>
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <Typography>{values.year}</Typography>
                            <IconButton onClick={() => setFieldValue('year', values.year + 1)}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                            <Typography>{`tiene ${values.dates.length} dias no laborables (incluidos fines de semana).`}</Typography>
                            <div className={classes.actions}>
                                <Button
                                    color='outlined'
                                    onClick={() => {
                                        setFieldValue('dates', calendariosProvider.getDefaultNoLaborables(values.year));
                                    }}
                                    startIcon={<ReplayIcon />}
                                >
                                    Reiniciar a solo fines de semana
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <DayPicker
                                className={classes.calendar}
                                disableNavigation
                                locale={es}
                                month={new Date(values.year, 0)}
                                mode='multiple'
                                selected={values.dates}
                                onSelect={(dates) => {
                                    setFieldValue('dates', dates || []);
                                    // setHasChanged(true);
                                }}
                                numberOfMonths={12}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

export default withButtonOpener(EditCalendarioDialog);

EditCalendarioDialog.propTypes = {
    id: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    open: PropTypes.any,
};
